import { ClientStatus } from '__generated__/types';
import { useConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useFilter } from 'utils/filterContext';

const HostFilters = () => {
  const { hostFilter, setHostFilter, authFilter } = useFilter();
  const filters = {
    Phones: 'Phone',
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    PC_07: 'PC07',
    PC_08: 'PC08',
  };

  const { data: accs } = useConnectionSessionWAList({ variables: { filter: {} } });
  const [filteredAccounts, setFilteredAccounts] = useState<
    (ClientStatus | null)[] | null | undefined
  >();

  useEffect(() => {
    const filteredAccounts = accs?.connectionSessionWAList
      ?.filter((acc) =>
        authFilter.toLowerCase()?.includes(acc?.waClient?.status?.toString()?.toLowerCase() || '')
      )
      .map((acc) => ({ ...acc, _id: acc?.waClient?._id || '' }));
    setFilteredAccounts(filteredAccounts || null);
  }, [accs, authFilter]);

  return (
    <motion.div
      key="host-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(filters).map(([key, value], index) => (
        <button
          key={index}
          onClick={() => {
            hostFilter === value ? setHostFilter('') : setHostFilter(value);
          }}
          className={`littleButton ${hostFilter === value ? 'bg-green-600' : 'bg-surfaceDarker dark:bg-surfaceDark'} gap-2 px-3 py-2`}
        >
          <p className="text-sm font-semibold dark:text-white">{key}</p>
          {filteredAccounts &&
            filteredAccounts?.filter((acc) => acc?.waClient?.host?.includes(value)).length > 0 && (
              <p className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-yellow-500/40 text-sm text-gray-600 dark:text-white">
                {filteredAccounts?.filter((acc) => acc?.waClient?.host?.includes(value)).length}
              </p>
            )}
        </button>
      ))}
    </motion.div>
  );
};

export default HostFilters;
