import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from 'shared/assets/icons/icons';
import HostFilters from 'shared/components/hostFilters/hostFilters';
import ThemeTumbler from 'shared/components/themeTumbler/ui/themeTumbler';

export const Header = () => {
  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');

  return (
    <header className="flex h-[100px] w-full flex-row items-center justify-between px-[1%]">
      <div className="flex flex-row items-center gap-x-4">
        <Link to="/">
          <Logo />
        </Link>
        {isAuthorizePage && <HostFilters />}
      </div>
      <div className="flex flex-row items-center gap-x-4">
        <ThemeTumbler />
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </div>
    </header>
  );
};

export default Header;
