import { Badge, ConfigProvider, Tooltip } from 'antd';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bell } from 'shared/assets/icons/icons';
import { themeIsDark } from 'utils/appTheme';
import { useFilter } from 'utils/filterContext';
import PassedNovoregCount from 'utils/passedNovoregCount';

interface AccountFilterProps {
  linkFilter: string;
  className?: string;
  allAccounts?: number;
  activeAccounts?: number;
}

export const AccountFilter = memo((props: AccountFilterProps) => {
  const { linkFilter, className, allAccounts, activeAccounts } = props;
  const navigate = useNavigate();

  const hostFilters = {
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    PC_07: 'PC07',
    PC_08: 'PC08',
    Phones: 'Phone',
  };

  const passedNovoregCount = PassedNovoregCount();

  const { filter, setFilter, authFilter, setAuthFilter, hostFilter, setHostFilter } = useFilter();
  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');
  const isWaChatsPage = location.pathname.includes('wachats');

  const handleFilterClick = (newFilter: string) => {
    if (isAuthorizePage) {
      if (newFilter in hostFilters) {
        setHostFilter(
          hostFilter === linkFilter.replace('_', '') ||
            hostFilter === linkFilter.replace('Phones', 'Phone')
            ? ''
            : hostFilters[newFilter as keyof typeof hostFilters]
        );
      } else {
        setAuthFilter(authFilter === linkFilter ? '' : newFilter);
      }
    } else {
      if (newFilter in hostFilters) {
        setHostFilter(
          hostFilter === linkFilter.replace('_', '') ||
            hostFilter === linkFilter.replace('Phones', 'Phone')
            ? ''
            : hostFilters[newFilter as keyof typeof hostFilters]
        );
      } else {
        setFilter(filter === linkFilter ? '' : newFilter);
        if (isWaChatsPage) {
          navigate('/wachats');
        }
      }
    }
  };

  return (
    <div className={`${className}`}>
      <ConfigProvider
        theme={{
          components: {
            Badge: {
              indicatorZIndex: 10,
              textFontWeight: '500',
            },
          },
          token: {
            colorBorderBg: 'transparent',
          },
        }}
      >
        <Badge
          count={(activeAccounts || allAccounts) && activeAccounts + '/' + allAccounts}
          size="small"
          className="w-full select-none"
          style={{ color: '#fefefe' }}
          color={`${themeIsDark ? '#505b65' : '#6f6f6f'}`}
          overflowCount={400}
          offset={[-9, 0]}
        >
          <button
            onClick={() => handleFilterClick(linkFilter)}
            className={`${className} relative flex w-full select-none justify-center whitespace-nowrap rounded-2xl px-2 py-[9px] font-semibold duration-200 dark:text-white ${
              (!isAuthorizePage && filter === linkFilter) ||
              (isAuthorizePage && authFilter === linkFilter) ||
              hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
                ? 'bg-green-600'
                : 'inactiveLink'
            }`}
          >
            {(passedNovoregCount || 0) >= 20 && linkFilter.toLowerCase().includes('novoreg') && (
              <Tooltip title={`Отлежалось ${passedNovoregCount} аккаунтов`}>
                <motion.div
                  key={'bell'}
                  animate={{ rotateZ: [-20, 20] }}
                  transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: 'mirror',
                  }}
                  className="absolute bottom-0 left-6 top-0 my-auto"
                >
                  <Bell className="size-4" />
                </motion.div>
              </Tooltip>
            )}
            <p className="select-none">{linkFilter}</p>
          </button>
        </Badge>
      </ConfigProvider>
    </div>
  );
});
