import { SearchOutlined } from '@ant-design/icons';

interface Props {
  isOpen: boolean;
  handleClick: () => void;
}

const UserSearchWidget = ({ handleClick }: Props) => {
  return (
    <div
      className="group absolute right-0 top-20 flex w-12 cursor-pointer flex-row items-center gap-x-2 rounded-l-xl bg-surfaceDarker px-3 py-2 shadow-md duration-200 hover:w-48 dark:bg-surfaceDark"
      onClick={handleClick}
    >
      <SearchOutlined className="text-xl dark:text-white" />
      <p className="absolute right-3 translate-x-20 select-none whitespace-nowrap text-sm opacity-0 transition-all duration-100 group-hover:translate-x-0 group-hover:opacity-100 dark:text-white">
        Найти пользователя
      </p>
    </div>
  );
};

export default UserSearchWidget;
